import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const url = 'https://api2.transporterobledo.com/'
// const url = 'http://localhost:8000/'
export default new Vuex.Store({
  state: {
    url: url,
    accessToken: null,
    roles: ['guest']
  },
  mutations: {
    setToken (state, token) {
      state.accessToken = token
    },
    clearToken (state) {
      state.accessToken = null
    },
    setRoles (state, roles) {
      state.roles = roles
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {

  }
})
